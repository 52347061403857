












































































































































































import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import pieChart from '@/components/chart/pieChart.vue'
import asType from './asComponents/asType.vue'
import drawCom from './asComponents/drawCom.vue'
import drawInsCom from './asComponents/drawIns.vue'
import { getAssetsList, getFourTypeData } from '@/api/asKYC'
import { MyCompony, OptArr, AssetsType, AslistItem, SeriesType } from '@/types/kyc'
import { CommonMudule } from '@/store/modules/common'
import { deletePlan, getPlan, fetchEarnList, riskTypeFind, riskTypeSave, savePlan } from '@/api/portfolio'
import { PortMudule } from '@/store/modules/portfolio'
import dialogcom from '@/components/dialog/index.vue'
import { mixins } from 'vue-class-component'
import AnalyMixins from '@/utils/mixins/analy-mixins'
import { getcomplete } from '@/api/common'
@Component({
  name: 'asKYC',
  components: {
    moduleHeader,
    drawCom,
    pieChart,
    asType,
    drawInsCom,
    dialogcom
  }
})

export default class extends mixins(AnalyMixins) {
  private hasDebt = false
  private assetsList: AslistItem[] = []
  private typeData: AssetsType[] = []
  private canNext:boolean = false
  private hasInvest: boolean = false
  private planObj = {
    id: PortMudule.investmentPlanId,
    customerId: CommonMudule.customerId,
    riskType: ''
  }
  private series: SeriesType[] = [{
    radius: [0, '65%'],
    type: 'pie',
    top: '9%',
    bleedMargin: 30,
    hoverAnimation: true,
    data: []
  }]
  showDialog = false
  showTop = true
  value = ''
  riskTypes = ''
   options = [{
     value: 'Conservative',
     label: '保守型'
   }, {
     value: 'ConservativeModerately',
     label: '适度保守型'
   }, {
     value: 'Steady',
     label: '稳健性'
   }, {
     value: 'PositiveModerately',
     label: '适度积极型'
   }, {
     value: 'Positive',
     label: '积极型'
   }]
   earnData = [
     {
       type: '历史组合收益',
       Conservative: '',
       ConservativeModerately: '',
       Steady: '',
       PositiveModerately: '',
       Positive: ''
     },
     {
       type: '组合历史最大回撤',
       Conservative: '',
       ConservativeModerately: '',
       Steady: '',
       PositiveModerately: '',
       Positive: ''
     }
   ]
   hadAsset = []
   created() {
     this.getTrue()
     if (this.$route.params.id === 'plan') {
       this.showTop = true
     } else {
       this.showTop = false
     }
     this.initFetch()
     this.getFetchEarnList()
     this.riskTypeFind()
   }

   async submit() {
     if (!this.riskTypes && this.showTop) {
       this.$message({
         type: 'error',
         message: '请选择风险类型'
       })
       return
     }
     if (!this.canNext) {
       this.$message({
         type: 'error',
         message: '请至少填写一项资产信息（不包含负债）'
       })
       return
     }
     if (this.showTop) {
       if (this.change()) {
         this.$router.push('/portfolio/index')
       }
     } else {
       this.$router.push('/analys/analyChart')
     }
   }

   preSubmit() {
     this.showDialog = true
   }

   async initFetch() {
     this.getInvest()
     const resArr = await Promise.all([getAssetsList(), getFourTypeData({ customerId: CommonMudule.customerId })])
     if (resArr[0].statusCode === 0) {
       const { data } = resArr[0]
       let datTemp = []
       if (this.$route.params.id === 'plan') {
         datTemp = data.filter((item:any) => {
           return item.id !== 'total-debt-0001' && item.id !== 'total-insurance-0001'
         })
       } else {
         datTemp = data
       }
       datTemp.map((item:any) => {
         // @ts-ignore
         const res:{hadAsset:boolean} = this.hadAsset.find((mt:any) => mt.id === item.id)
         if (res) {
           item.visible = res.hadAsset
         }
         return item
       })
       this.assetsList = datTemp
     }

     if (resArr[1].statusCode === 0) {
       const { data } = resArr[1]
       this.typeData = data
       this.addSeris(data)
     }
     console.log('resArr', resArr)
   }
   private addSeris(data: AssetsType[]) {
     this.series[0].data = []
     for (const v of data) {
       if (v.proportion) {
         this.series[0].data.push({
           name: v.assetTypeName,
           value: v.proportion,
         })
       }
     }
   }

   private async getInvest() {
     const res = await getPlan({
       customerId: CommonMudule.customerId
     })
     if (res.statusCode === 0) {
       if (res.data) {
         this.planObj = res.data
         this.hasInvest = true
         PortMudule.SET_PLANID(res.data.id)
       } else {
         this.hasInvest = false
       }
     } else {
       this.$message({
         type: 'error',
         message: res.errorMsg
       })
     }
   }
   async getFetchEarnList() {
     const resArr = await fetchEarnList()
     if (resArr.statusCode === 0) {
       const typeObj = {
         type: '历史组合收益',
         Conservative: '',
         ConservativeModerately: '',
         Steady: '',
         PositiveModerately: '',
         Positive: ''
       }
       for (const val of resArr.data) {
         for (const key in typeObj) {
           if (val.riskType === key) {
             (this.earnData[0] as any)[key] = val.annualRateOfReturn;
             (this.earnData[1] as any)[key] = val.maxDrawback
           }
         }
       }
       console.log('this.earnData', this.earnData)
     }
   }
   async onLeftFun() {
     const res = await deletePlan(`investmentPlanId=${PortMudule.investmentPlanId}`)
     console.log('deletePlan', res)
     if (res.statusCode === 0 && res.data) {
       this.$message({
         type: 'success',
         message: '操作成功'
       })
       this.showDialog = false
       this.$router.push('/portfolio/index')
     } else {
       this.$message({
         type: 'error',
         message: res.errorMsg
       })
     }
   }
   async riskTypeFind() {
     const res = await riskTypeFind({ customerId: CommonMudule.customerId })
     if (res.success) {
       this.riskTypes = res.data.riskType
     }
   }
   async change() {
     const p = {
       customerId: CommonMudule.customerId,
       riskType: this.riskTypes
     }
     this.planObj.riskType = this.riskTypes
     const res = await riskTypeSave(p)
     if (this.hasInvest) {
       const res2 = await savePlan(this.planObj)
       if (res.success && res2.statusCode === 0 && res2.data) {
         // @ts-ignore
         PortMudule.SET_RISKTYPE(this.riskTypes)
         return true
       }
     } else {
       if (res.success) {
         // @ts-ignore
         PortMudule.SET_RISKTYPE(this.riskTypes)
         return true
       }
     }
   }

   onBack() {
     this.$router.push('/analys/index')
   }

   onRightFun() {
     this.showDialog = false
   }
   async getTrue() {
     const res = await getcomplete({ customerId: CommonMudule.customerId })
     if (res.statusCode === 0 && res.data) {
       this.canNext = res.data.find((item: any) => {
         if (item.id !== 'total-debt-0001' && item.hadAsset) {
           return true
         }
       })
     }
     this.hadAsset = res.data
     this.initFetch()
   }
}



































import { Vue, Component, Prop } from 'vue-property-decorator'
import { AslistItem } from '@/types/kyc'

@Component({
  name: 'asType'
})
export default class extends Vue {
  @Prop({ required: true }) private data!: AslistItem
  private hoverData = [
    {
      assetTypeId: 'root-asset-0001',
      desTitle: '现金类资产包含：',
      desContent: '活期存款，半年内期限定期存款，半年内期限的固收银行理财，货币公募基金，余额宝类互联网理财等'
    }, {
      assetTypeId: 'root-asset-0003',
      desTitle: '权益类资产包含：',
      desContent: '权益类银行理财，权益类信托产品，智能投顾组合，股票类公募基金，股票，权益类券商资管，对冲基金等'
    }, {
      assetTypeId: 'root-asset-0002',
      desTitle: '固收类资产包含：',
      desContent: '定期存款，半年期以上的固收银行理财，子女教育账户，固收类信托产品，债券公募基金，政府债，企业债，固收类券商资管，网贷类资产，金交所类非标产品，民间借贷等'
    }, {
      assetTypeId: 'root-asset-0004',
      desTitle: '另类资产包含：',
      desContent: '数字货币，大宗商品以及金融衍生品基金，地产私募基金，股权基金以及股权母基金，投资性房产，股权项目直投，贵金属，艺术品古董投资，期货等'
    }
  ]
  private getContent(val: string) {
    const ts = this.hoverData.find((item) => item.assetTypeId === val)
    return ts
  }
}














































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import handleBtn from '@/components/handleBtn/index.vue'
import { AsListInterface, AsDetailIterface, AsDetailItem } from '@/types/kyc'
import { CommonMudule } from '@/store/modules/common'
import { getAssetsInfo, saveAssetItem } from '@/api/asKYC'
import { filterReqData, addDefault } from './tools'
import { Drawer } from 'element-ui'
import formItem from '@/components/formItem/index.vue'
import { parsePrice, getInputValue } from '@/utils/validate'

@Component({
  name: 'drawItem',
  components: {
    handleBtn,
    moduleHeader,
    formItem
  }
})
export default class extends Vue {
  @Prop({ required: true }) private data!: AsListInterface
    private detailObj: AsDetailIterface = {
      assetInputType: null,
      customerAssetSubList: [],
      customerId: '',
      parentAssetTypeId: '',
      parentAssetTypeName: ''
    }
    dialog = false
    loading = false
    private complete = false
    private inputData = this.data
    private value = 0
    get options() {
      return CommonMudule.curencyData
    }
    options2 = [
      {
        value: 'currency-id-0001',
        label: '人民币'
      }
    ]

    async fetchDetail() {
      const res = await getAssetsInfo({
        assetTypeId: this.data.id,
        customerId: CommonMudule.customerId
      })
      if (res.statusCode === 0 && res.data) {
        this.dialog = true
        console.log(' addDefault(res.data)', addDefault(res.data))
        this.detailObj = addDefault(res.data)
      }
    }
    // @Watch('detailObj.customerAssetSubList', { immediate: false, deep: false })
    // getSublist(val:any) {
    //   const res = val.find((item:any) => { return item.assetInputType === 'AmountCompanyPer' })
    //   if (!res) return
    //   const sum = res.customerMemberAssetPerList.reduce((prev:any, cur:any) => {
    //     return prev + cur.per
    //   }, 0)
    //   if (sum > 100 || sum === 0) {
    //     this.$message({
    //       type: 'error',
    //       message: '您的家庭信托额度配置超过/低于总金额，请调整后输入'
    //     })
    //     this.errorText = true
    //   } else {
    //     this.errorText = false
    //   }
    // }

    changeper(item:any, out:number) {
      const sum = this.detailObj.customerAssetSubList[out].customerMemberAssetPerList.reduce((prev, cur) => {
        return prev + cur.per
      }, 0)
      if (sum > 100 || sum === 0) {
        this.$message({
          type: 'error',
          message: '您的' + item.assetTypeName + '额度配置超过总金额，请调整后输入'
        })
      }
    }
    handlePatch(item:any, out:number) {
      const num = this.detailObj.customerAssetSubList[out].customerMemberAssetPerList.length
      this.detailObj.customerAssetSubList[out].customerMemberAssetPerList.map((item:any) => {
        item.per = Number(100 / num)
      })
    }
    private addInfo(addData: AsDetailItem, asIndex: number) {
      this.detailObj.customerAssetSubList[asIndex].customerAssetList.push({
        deleted: false,
        assetTypeId: addData.assetTypeId,
        customerId: this.detailObj.customerId,
        customerMemberId: addData.customerMemberId,
        region: null,
        assetAmount: null,
        currencyId: 'currency-id-0001',
        currencyName: null,
        companyType: 'NoneSelfCompany',
        systemAssetType: addData.systemAssetType
      })
    }

    private removeInfo(index:number, idx: number) {
      this.detailObj.customerAssetSubList[index].customerAssetList[idx].deleted = true
    }

    private blurFilter(index:number, idx: number, value: any, assetAmount: number) {
      console.log('value', value)
      const e:Event = window.event as Event
      const val = getInputValue(e)
      console.log('val', val)
      if (!value) {
        this.detailObj.customerAssetSubList[index].customerAssetList[idx].isClear = true
      }
      console.log(' this.detailObj.customerAssetSubList[index].customerAssetList[idx].assetAmount', this.detailObj.customerAssetSubList[index].customerAssetList[idx].assetAmount)
      this.detailObj.customerAssetSubList[index].customerAssetList[idx].assetAmount = val
    }

    private async confirm() {
      const totalArr: Array<{total:number, tip: string}> = []
      this.detailObj.customerAssetSubList.forEach((item) => {
        if (item.assetInputType === 'AmountCompanyPer') {
          const assetAmount = item.customerAssetList.every((nt) => {
            return nt.assetAmount
          })
          if (assetAmount) {
            if (item.customerMemberAssetPerList.length > 0) {
              const total = item.customerMemberAssetPerList.reduce((prev:any, cur:any) => {
                return prev + parsePrice(cur.per)
              }, 0)
              totalArr.push({ total, tip: item.assetTypeName })
            }
          }
        }
      })
      const item = totalArr.find(item => item.total > 100)
      if (item) {
        this.$message({
          type: 'info',
          message: '您的' + item.tip + '额度配置超过总金额，请调整后输入'
        })
        return
      }
      const res = await saveAssetItem(filterReqData(this.detailObj))
      if (res.statusCode === 0 && res.data) {
        this.$message({
          type: 'success',
          message: '保存成功'
        })
        this.dialog = false
        this.$emit('refresh')
      }
    }
    private handleClose() {
      if (this.loading) {
        return
      }
      this.loading = false
      this.dialog = false
    }

    cancelForm() {
      this.loading = false
      this.dialog = false
    }
    created() {
    }
}
